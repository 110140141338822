<template>
	<div class="note-wrapper" id="Feeds">
		<div class="form-valide">
			<error-message type="alert" class="mb-5" :error-list="generalErrors" />
			<div class="form-group row">
				<div class="col-lg-12">
					<textarea rows="5" class="form-control" v-model="note" :placeholder="translations.notes"></textarea>
					<error-message :error-list="fieldErrors.note" />
					<error-message :error-list="fieldErrors.file" />
				</div>
			</div>

			<div class="form-group row">
				<div class="col-lg-12">
					<label class="h3 text-muted" role="button">
						<span v-text="filename ? filename : translations.attachment"></span>
						<input @change="updateFileName" class="d-none" type="file" ref="feed" />
					</label>
					<button @click="send" class="btn btn-primary float-right" :class="{ 'disabled': formLoading }">
						{{ translations.save }}
					</button>
				</div>
			</div>
		</div>
		<div class="notes-listing mt-5">
			<div
				class="font-italic mb-3 h4"
				:class="[collapseListing ? 'text-dark' : 'text-primary']"
				role="button"
				@click="collapseListing = !collapseListing"
			>
				{{ translations.note_history }}
				<span v-show="list?.length"> ({{ list.length }}) </span>
			</div>
			<Transition>
				<div v-show="!collapseListing">
					<div v-for="item in list" :key="item.id">
						<hr />
						<div class="row">
							<div class="col-md-9">
								<p>{{ item.note }}</p>
								<span class="float-right written_by">{{ item.author_name }}</span>
							</div>
							<u class="col-md-1 text-center">
								<a v-if="item.file" :href="item.file" download>{{
									translations.file
								}}</a>
							</u>
							<div class="col-md-2">
								{{ item.created_date }}
								<button v-if="hasDeletePermission" 
									@click="deleteFeed(item.id)"
									class="btn btn-danger float-right" :class="{ 'disabled': formLoading }">
									{{ translations.delete }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</div>
	</div>
</template>
<script>
import FormMixin from "@mixins/Form";
export default {
	mixins: [FormMixin],
	props: ["listUrl", "feedHistory", "hasDeletePermission"],
	data() {
		return {
			list: [],
			collapseListing: false,
			note: "",
			filename: false,
		};
	},
	methods: {
		updateFileName() {
			const files = this.$refs.feed.files;
			this.filename = files[0]? files[0].name : false;
		},
		send(e) {
			if (this.formLoading || !this.note) {
				return;
			}
			this.formLoading = true;

			let sendData = new FormData();

			const files = this.$refs.feed.files;
			if (files.length) {
				sendData.append("file", files[0]);
			}
			sendData.append("note", this.note);

			axios({
				url: this.sendUrl,
				method: "POST",
				data: sendData,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
				.then(({ data }) => {
					this.toast("success", this.translations.success_message || "Success");
					this.note = "";
					this.list = data;
				})
				.catch(({ response }) => {
					this.onFormError(response);
				})
				.finally(() => {
					this.onFormFinish();
				});
		},
		loadFeeds() {
			axios({ url: this.listUrl, method: "GET" })
				.then(({ data }) => {
					this.list = data;
				})
				.catch(({ response }) => {
					this.onFormError(response);
				})
				.finally(() => {
					this.formLoading = false;
				});
		},
		deleteFeed(id) {
			const url = `/leads/feeds/${id}/delete/`
			axios({url: url, method: "DELETE"}).then(() => 
				this.list = this.list.filter(item => item.id !== id)
			)
		}
	},
	created() {
		if (this.feedHistory) {
			this.list = this.feedHistory
		}
	},
};
</script>