<template>
	<div class="form-valide">
		<error-message type="alert" class="mb-5" :error-list="generalErrors" />
		<div class="row">
			<div class="col-xl-6">
				<input-group :required="true" :label="translations.gender" :error-list="fieldErrors.gender">
					<select
						v-model="form.gender"
						class="form-control default-select"
						:disabled="disabledInputs?.gender"
					>
						<enum-choices :default-option="translations.please_select" :choices="genderChoices">
						</enum-choices>
					</select>
				</input-group>

				<input-group :required="true" :label="translations.date_of_birth" :error-list="fieldErrors.birthdate">
					<input
						type="text"
						class="form-control"
						v-model="form.birthdate"
						:disabled="disabledInputs?.birthdate"
					/>
				</input-group>

				<input-group
					:required="true"
					:label="translations.allergic_to_medicines"
					:error-list="fieldErrors.allergies"
				>
					<select
						class="form-control default-select"
						multiple
						v-model="form.allergies"
						:disabled="disabledInputs?.allergies"
					>
						<option value="" readonly>
							{{ translations.please_select }}
						</option>
						<option v-for="medicine in medicines" :key="medicine" :value="medicine">
							{{ medicine }}
						</option>
					</select>
				</input-group>

				<input-group
					:required="true"
					:label="translations.previous_operations"
					:error-list="fieldErrors.previous_operations"
				>
					<select
						class="form-control default-select"
						multiple
						v-model="form.previous_operations"
						:disabled="disabledInputs?.previous_operations"
					>
						<option value="" readonly>
							{{ translations.please_select }}
						</option>
						<option v-for="operation in operations" :key="operation" :value="operation">
							{{ operation }}
						</option>
					</select>
				</input-group>

				<input-group :label="translations.notes" :error-list="fieldErrors.note">
					<textarea
						rows="5"
						class="form-control"
						v-model="form.note"
						:placeholder="translations.notes_placeholder"
						:disabled="disabledInputs?.note"
					></textarea>
				</input-group>
			</div>
			<div class="col-xl-6">
				<input-group
					:required="true"
					:label="translations.used_medicines"
					:error-list="fieldErrors.used_medicines"
				>
					<select
						class="form-control default-select"
						multiple
						v-model="form.used_medicines"
						:disabled="disabledInputs?.used_medicines"
					>
						<option value="" readonly>
							{{ translations.please_select }}
						</option>
						<option v-for="medicine in medicines" :key="medicine" :value="medicine">
							{{ medicine }}
						</option>
					</select>
				</input-group>

				<input-group :required="true" :label="translations.diseases" :error-list="fieldErrors.diseases">
					<select
						class="form-control default-select"
						multiple
						v-model="form.diseases"
						:disabled="disabledInputs?.diseases"
					>
						<option value="" readonly>
							{{ translations.please_select }}
						</option>
						<option v-for="disease in diseases" :key="disease" :value="disease">
							{{ disease }}
						</option>
					</select>
				</input-group>

				<input-group :required="true" :label="translations.smoking" :error-list="fieldErrors.smoking">
					<select v-model="form.smoking" class="form-control default-select">
						<enum-choices :default-option="translations.please_select" :choices="smokingChoices">
						</enum-choices>
					</select>
				</input-group>

				<input-group :required="true" :label="translations.alcohol" :error-list="fieldErrors.alcohol">
					<select
						v-model="form.alcohol"
						:disabled="disabledInputs?.alcohol"
						class="form-control default-select"
					>
						<enum-choices :default-option="translations.please_select" :choices="alcoholChoices">
						</enum-choices>
					</select>
				</input-group>

				<input-group :label="translations.diagnoses" :error-list="fieldErrors.diagnose">
					<input
						type="text"
						class="form-control"
						v-model="form.diagnose"
						:disabled="disabledInputs?.diagnose"
					/>
				</input-group>

				<div class="form-group row">
					<div class="col-lg-8 ml-auto">
						<button @click="send" class="btn btn-primary" :class="{ 'disabled': formLoading }">
							{{ translations.save }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import FormMixin from "@mixins/Form";
export default {
	mixins: [FormMixin],
	props: ["genderChoices", "smokingChoices", "alcoholChoices", "operations", "diseases", "medicines"],
};
</script>