import "./extend.js"
import { createApp } from 'vue'
import vSelect from 'vue-select'

const app = createApp({
	data() {
		return {
			values: {}
		}
	},
	methods: {
		updateLeadHistory() {
			this.$refs.leadhistory.loadHistory()
		}
	},
	delimiters: ['${', '}']
});
app.config.globalProperties.window = window

window.app = app;

import hey from './components/Hey.vue'
import badger from './components/Badger.vue'
import citySelect from './components/CitySelect.vue'
import agentSelector from './components/AgentSelector.vue'
import leadInfoForm from './components/LeadInfoForm.vue'
import leadMedicalInfoForm from './components/LeadMedicalInfoForm.vue'
import leadMarketingForm from './components/LeadMarketingForm.vue'
import leadFeedForm from './components/leadFeedForm.vue'
import leadHistoryList from './components/leadHistoryList.vue'
import leadSalesForm from './components/leadSalesForm.vue'
import errorMessage from './components/ErrorMessage.vue'
import inputGroup from './components/InputGroup.vue'
import enumChoices from './components/EnumChoices.vue'
import feedNotification from './components/FeedNotification.vue'
import historyNotification from './components/HistoryNotification.vue'

app.component('v-select', vSelect)
app.component("hey", hey)
app.component("badger", badger)
app.component("city-select", citySelect)
app.component("agent-selector", agentSelector)
app.component("lead-info-form", leadInfoForm)
app.component("lead-medical-info-form", leadMedicalInfoForm)
app.component("lead-marketing-form", leadMarketingForm)
app.component("lead-feeds-form", leadFeedForm)
app.component("lead-history-list", leadHistoryList)
app.component("lead-sales-form", leadSalesForm)
app.component("error-message", errorMessage)
app.component("input-group", inputGroup)
app.component("enum-choices", enumChoices)
app.component("feed-notification", feedNotification)
app.component("history-notification", historyNotification)

app.mount('#app');