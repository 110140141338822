<template>
	<div class="form-group row">
		<label class="col-lg-4 col-form-label">
			{{ label }}
			<span v-if="required" class="text-danger">*</span>
		</label>
		<div class="col-lg-6">
			<slot></slot>
			<error-message :error-list="errorList" />
		</div>
	</div>
</template>

<script>
export default {
	props: ["label", "errorList", "required"],
};
</script>
