<template>
	<form @submit.prevent="send" ref="form" class="form-valide" id="Lead">
		<error-message type="alert" class="mb-5" :error-list="generalErrors" />
		<div class="row">
			<div class="col-xl-6">
				<input-group :required="false" :label="translations.lead_owner" :error-list="fieldErrors.agent">
					<agent-selector
						:filterable="false"
						:get-agent-url="userSearchUrl"
						:selected="form.agent"
						v-slot="instance"
					>
						<v-select
							id="lead-owner"
							label="full_name"
							:reduce="(agent) => agent.id"
							:options="instance.agents"
							@search="instance.searchDelayed"
							v-model="form.agent"
							:disabled="disabledInputs?.agent"
						>
						</v-select>
						<input type="hidden" :disabled="disabledInputs?.agent" v-model="form.agent" />
					</agent-selector>
				</input-group>

				<input-group :required="true" :label="translations.patient_name" :error-list="fieldErrors.name">
					<input
						type="text"
						class="form-control"
						v-model="form.name"
						:disabled="disabledInputs?.name"
						:placeholder="translations.enter_a_name"
					/>
				</input-group>

				<input-group :label="translations.e_mail" :error-list="fieldErrors.email">
					<input
						type="email"
						class="form-control"
						v-model="form.email"
						:disabled="disabledInputs?.email"
						:placeholder="translations.enter_a_valid_email"
					/>
				</input-group>

				<input-group :required="true" :label="translations.phone_number" :error-list="fieldErrors.phone">
					<input
						type="text"
						class="form-control"
						v-model="form.phone"
						:disabled="disabledInputs?.phone"
						:placeholder="translations.enter_a_phone_number"
					/>
				</input-group>
				<template v-if="stages">
					<input-group :required="true" :label="translations.lead_stage" :error-list="fieldErrors.stage">
						<select class="form-control default-select" v-model="form.stage" :disabled="disabledInputs?.stage">
							<option value="" readonly>
								{{ translations.please_select }}
							</option>
							<option class="text-muted" v-for="stage in stages" :key="stage.id" :disabled="!allowedStage(stage.id)" :readonly="!allowedStage(stage.id)" :value="stage.id">
								{{ stage.label }}
							</option>
						</select>
					</input-group>
					<div v-show="form.stage === 8">
						<input-group :label="translations.departure_date" :error-list="fieldErrors.departure_date">
							<input
								type="text"
								class="form-control datetime-picker"
								ref="departure_date"
								:value="form.departure_date"
								:disabled="disabledInputs?.departure_date"
								:placeholder="translations.departure_date"
							/>
						</input-group>
						<input-group :label="translations.return_date" :error-list="fieldErrors.return_date">
							<input
								type="text"
								class="form-control datetime-picker"
								ref="return_date"
								:value="form.return_date"
								:disabled="disabledInputs?.return_date"
								:placeholder="translations.return_date"
							/>
						</input-group>
					</div>
					<input-group v-if="form.stage && statusList.length" :required="true" :label="translations.lead_status" :error-list="fieldErrors.status">
						<select class="form-control default-select" v-model="form.status" required :disabled="disabledInputs?.status">
							<option value="">
								{{ translations.please_select }}
							</option>
							<option class="text-muted" v-for="(status,key) in statusList" :key="key" :value="status.value">
								{{ status.label }}
							</option>
						</select>
					</input-group>
					<input-group v-if="form.stage && form.status && reasonList.length" :required="true" :label="translations.lead_reason" :error-list="fieldErrors.reason">
						<select class="form-control default-select" v-model="form.reason" required :disabled="disabledInputs?.reason">
							<option value="">
								{{ translations.please_select }}
							</option>
							<option class="text-muted" v-for="(reason,key) in reasonList" :key="key" :value="reason.value">
								{{ reason.label }}
							</option>
						</select>
					</input-group>
				</template>
			</div>
			<city-select
				class="col-xl-6"
				:get-city-url="citiesListUrl"
				:selected-country="form.country"
				:selected-city="form.city"
				v-slot="instance"
			>
				<input-group :required="false" :label="translations.country" :error-list="fieldErrors.country">
					<select
						:disabled="instance.loading_cities || disabledInputs?.country"
						:readonly="instance.loading_cities"
						@change="instance.updateCities"
						class="form-control"
						v-model="form.country"
					>
						<option value="" readonly>
							{{ translations.please_select }}
						</option>
						<option v-for="country in countries" :key="country.id" :value="country.id">
							{{ country.name }}
						</option>
					</select>
				</input-group>

				<input-group :required="false" :label="translations.city" :error-list="fieldErrors.city">
					<select
						v-show="!instance.loading_cities"
						:disabled="instance.loading_cities || disabledInputs?.city"
						class="form-control"
						v-model="form.city"
					>
						<option value="" readonly>
							{{ translations.please_select }}
						</option>
						<option v-for="city in instance.cities" :key="city.id" :value="city.id">
							{{ city.name }}
						</option>
					</select>
				</input-group>

				<input-group :required="false" :label="translations.language" :error-list="fieldErrors.language">
					<select
						class="form-control default-select"
						v-model="form.language"
						:disabled="disabledInputs?.language"
					>
						<option value="" readonly>
							{{ translations.please_select }}
						</option>
						<option v-for="language in languages" :key="language.id" :value="language.id">
							{{ language.name }}
						</option>
					</select>
				</input-group>

				<input-group :label="translations.notes" :error-list="fieldErrors.note">
					<textarea
						:disabled="disabledInputs?.note"
						class="form-control"
						v-model="form.note"
						rows="5"
						:placeholder="translations.notes_placeholder"
					></textarea>
				</input-group>

				<div class="form-group row">
					<div class="col-12">
						<div class="form-check">
							<input
								id="checkbox1"
								class="form-check-input"
								v-model="form.secondary"
								:disabled="disabledInputs?.secondary"
								type="checkbox"
							/>
							<label for="checkbox1" class="form-check-label">{{ translations.secondary }}</label>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-lg-8 ml-auto">
						<button @click="send" class="btn btn-primary" :class="{ 'disabled': formLoading }">
							{{ translations.save }}
						</button>
					</div>
				</div>
			</city-select>
		</div>
	</form>
</template>

<script>
import FormMixin from "@mixins/Form";
export default {
	mixins: [FormMixin],
	props: ["userSearchUrl", "languages", "countries", "stages", "citiesListUrl", "allowedStages", "statusOptions", "reasonOptions"],
	methods: {
		onFormCreated() {
			window.location.href = this.sendUrl;
		},
		onFormAfterSuccess() {
			this.$emit("lead-updated");
		},
		allowedStage(stage) {
			return this.allowedStages.indexOf(stage) >= 0
		},
		onBeforeFormSend() {
			const valid = this.$refs.form.reportValidity()
			return valid
		},
		send(event) {
			if (this.formLoading)
				return

			const send = this.onBeforeFormSend()
			if (!send) {
				return
			}

			this.formLoading = true;

			// use method prop or decide auto
			const method = this.method? this.method : (this.form.id ? "PATCH" : "POST")
		
			const departure_date = this.$refs.departure_date?.value;
			const return_date = this.$refs.return_date?.value;

			
			let data = {...this.form}
			if (departure_date && return_date){
				data = {
					...this.form,
					departure_date,
					return_date
				}
			}
			

			axios({ url: this.sendUrl, method, data: data })
				.then((response) => {
					this.onFormSuccess(response)
				}).catch(({ response }) => {
					this.onFormError(response)
				}).finally(() => {
					this.onFormFinish()
				});
		}
	},
	computed: {
		statusList() {
			const stage = this.form.stage;
			if (!stage) return []
			return this.statusOptions[stage];
		},
		reasonList() {
			const status = this.form.status;
			const stage = this.form.stage;
			if (status && stage){
				return this.reasonOptions[status][stage];
			}
			return []
		}
	}
};
</script>
