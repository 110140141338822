<template>
	<span class="badge" :class="badgeBgClass">
		<i v-if="withCircle" class="fa fa-circle mr-1"></i>
		{{ label || value }}
	</span>
</template>

<script>
export default {
	props: ["value", "label"],
	data: () => {
		return {
			active: false,
		};
	},
	computed: {
		withCircle() {
			if (
				["lost", "inactive", "waiting", "null", "frozen", "active", "completed", "won"].indexOf(this.value) >= 0
			) {
				return true;
			}
			return false;
		},
		badgeBgClass() {
			switch (this.value) {
				case "new_lead":
					return "badge-triaj";
				case "not_contacted":
					return "badge-not_contacted";
				case "tried_but_no_response":
					return "badge-tried_no_response";
				case "contacted":
					return "badge-contacted";
				case "photo_xray_awaited":
					return "badge-photo_awaited";
				case "under_evaluation":
					return "badge-under_evaluation";
				case "negotiation":
					return "badge-negotiation";
				case "flight_ticket":
					return "badge-flight_ticket";
				case "arrived":
					return "badge-arrived";
				case "treatment_over":
					return "badge-treatment_over";
				case "lost":
					return "badge-lost";
				case "inactive":
					return "badge-inactive";
				case "waiting":
					return "badge-waiting";
				case "null":
					return "badge-null";
				case "frozen":
					return "badge-frozen";
				case "active":
					return "badge-active";
				case "completed":
					return "badge-completed";
				case "won":
					return "badge-won";
			}
		},
	},
};
</script>
