<template>
	<div v-if="errorList?.length">
		<div v-if="type === 'alert'" class="alert alert-danger alert-dismissible fade show">
			<svg
				viewBox="0 0 24 24"
				width="24"
				height="24"
				stroke="currentColor"
				stroke-width="2"
				fill="none"
				stroke-linecap="round"
				stroke-linejoin="round"
				class="mr-2"
			>
				<polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
				<line x1="15" y1="9" x2="9" y2="15"></line>
				<line x1="9" y1="9" x2="15" y2="15"></line>
			</svg>
			<span v-html="joinedErrorList"></span>
			<button type="button" class="close h-100" data-dismiss="alert" aria-label="X">
				<span><i class="mdi mdi-close"></i></span>
			</button>
		</div>
		<template v-else>
			<small v-html="joinedErrorList" class="text-danger"></small>
		</template>
	</div>
</template>
<script>
export default {
	props: ["errorList", "type"],
	computed: {
		joinedErrorList() {
			return this.errorList?.join("<br/>");
		},
	},
};
</script>