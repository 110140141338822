import ToasterMixin from '@mixins/Toaster'

export default {
	mixins: [ToasterMixin],
	props: [
		'translations', 'sendUrl', 'item', 'method', 'itemAttribute', 'disabledInputs'
	],
	data: () => {
		return {
			responseErrors: {},
			generalErrors: [],
			fieldErrors: {},
			formLoading: true,
			form: {
			},
		}
	},
	methods: {
		buildErrors(errs) {
			this.generalErrors = []
			this.fieldErrors = {}

			if (errs) {
				for (const err_key in errs) {
					const errList = errs[err_key]
					if (["detail", "non_field_errors"].indexOf(err_key) > -1) {
						if (errList instanceof Array) {
							for (var i = 0; i < errList.length; i++) {
								this.generalErrors.push(errList[i])
							}
							continue
						}
						this.generalErrors.push(errList)
						continue
					}

					this.fieldErrors[err_key] = errList
				}
			}
		},
		onFormCreated(response) {},
		onFormAfterSuccess(response) {},
		onFormSuccess(response) {
			this.toast("success", this.translations.success_message || "Success")

			this.form = response.data

			if (response.status === 201) {
				this.onFormCreated(response)
			}
			this.onFormAfterSuccess(response)
		},
		onFormError(response) {
			this.toast("warning", this.translations.error_message || "Error")
			this.buildErrors(response.data)
		},
		onFormFinish() {
			this.formLoading = false
		},
		onBeforeFormSend() {
			return true;
		},
		send(event) {
			if (this.formLoading)
				return

			const send = this.onBeforeFormSend()
			if (!send) {
				return
			}

			this.formLoading = true;

			// use method prop or decide auto
			const method = this.method? this.method : (this.form.id ? "PATCH" : "POST")

			axios({ url: this.sendUrl, method, data: this.form })
				.then((response) => {
					this.onFormSuccess(response)
				}).catch(({ response }) => {
					this.onFormError(response)
				}).finally(() => {
					this.onFormFinish()
				});
		},
	},
	created() {
		if (this.item) {
			const key = this.itemAttribute
			this.form = key ? { ...this.item[key] } : { ...this.item }
		}
		this.formLoading = false
	},
	// custom action to support bootstrap-select
	beforeUpdate(){
		const selects = jQuery('.default-select')
		if(selects.length > 0 ){
			selects.selectpicker('destroy');
			selects.selectpicker();
		}
	},
}