<template>
	<div><slot v-bind="instance" /></div>
</template>

<script>
export default {
	props: ["getCityUrl", "selectedCountry", "selectedCity"],
	data: () => {
		return {
			loading_cities: false,
			cities: [],
		};
	},
	computed: {
		instance() {
			return { ...this };
		},
	},
	methods: {
		updateCities(event) {
			if (this.loading_cities) return;
			this.loading_cities = true;
			this.getCities(event.target.value);
		},
		getCities(value) {
			axios({ url: this.getCityUrl, method: "GET", params: { search: value } })
				.then(({ data }) => {
					this.cities = data;
					console.log(data);
				})
				.finally(() => {
					this.loading_cities = false;
				});
		},
	},
	created() {
		if (this.selectedCountry) {
			this.getCities(this.selectedCountry);
		}
	},
};
</script>
