<template>
	<div><slot v-bind="instance" /></div>
</template>

<script>
export default {
	props: ["getAgentUrl", "selected"],
	data: () => {
		return {
			searching_for: "",
			loading_agents: false,
			agents: [],
		};
	},
	computed: {
		instance() {
			return { ...this };
		},
	},
	methods: {
		searchDelayed(search, loading) {
			if (this.loading_agents || search.length < 3 || this.searching_for == search) return;

			const debounce = _.debounce(() => {
				this.updateAgents(search, loading);
			}, 300);
			debounce();
		},
		updateAgents(search, loading) {
			loading(true);
			this.searching_for = search;
			this.loading_agents = true;

			this.getAgents(search, () => loading(false));
		},
		getAgents(search, callback) {
			axios({ url: this.getAgentUrl, method: "GET", params: { search, limit: 300 } })
				.then(({ data }) => {
					if (data?.results) {
						this.agents = data.results;
					}
				})
				.finally(() => {
					this.loading_agents = false;
					callback();
				});
		},
	},
	created() {
		if (this.selected) {
			this.getAgents(this.selected, () => {});
		}
	},
};
</script>
