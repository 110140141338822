<template>
	<div class="table-responsive">
		<table id="ex" class="display vue-datatable-component min-w850">
			<thead>
				<tr>
					<th v-for="(header, key) in headers" :key="key">{{ header }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in list" :key="item.id">
					<td>{{ item.id }}</td>
					<td>{{ item.agent_fullname }}</td>
					<td>{{ item.modified_date }}</td>
					<td>{{ calculateDuration(item.created_date, list[index + 1]?.created_date) }}</td>
					<td>
						<badger :label="item.stage_label" :value="item.stage_name"></badger>
					</td>
					<td>
						<badger :label="item.status_label" :value="item.status"></badger>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import ToasterMixin from "@mixins/Toaster";
export default {
	mixins: [ToasterMixin],
	emits: ["lead-updated"],
	props: ["listUrl", "headers", "leadHistory"],
	data() {
		return {
			list: [],
			listLoading: false,
			datatable: false,
		};
	},
	methods: {
		calculateDuration(currentDate, nextDate) {
			if (!nextDate) {
				return "-";
			}
			const dateDiff = new Date(currentDate) - new Date(nextDate);
			const dateDiffValue = Math.ceil(Math.abs(dateDiff) / (1000 * 60 * 60 * 24));
			return dateDiffValue ? dateDiffValue : "-";
		},
		loadHistory() {
			if (this.listLoading) {
				return;
			}
			this.listLoading = true;

			axios({ url: this.listUrl, method: "GET" })
				.then(({ data }) => {
					this.list = data;
				})
				.catch(({ response }) => {
					this.toast("warning", this.translations.error_message || "Error");
				})
				.finally(() => {
					this.listLoading = false;
				});
		},
		updateDatatables() {
			if (this.datatable) {
				this.datatable.destroy();
			}
			const datatable = jQuery(".vue-datatable-component");
			this.datatable = datatable.DataTable({
				paging: false,
				searching: false,
				lengthChange: false,
				ordering: false,
				language: {zeroRecords: "", emptyTable: ""},
				filter: false,
				sort: false,
				bInfo : false,
				order: [],
			});
		},
	},
	created() {
		if (this.leadHistory) {
			this.list = this.leadHistory
		}
	},
	mounted() {
		this.updateDatatables();
	},
};
</script>