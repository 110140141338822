<template>
	<div>
		<span @click="loadAction">Hello</span>
		<div v-if="active">active</div>
	</div>
</template>

<script>
    export default {
    	props: ['src'],
    	data: () => {
    		return {
    			active: false
			}
    	},
        methods: {
        	loadAction(){
        		this.active = !this.active;
        		console.log(this.active)
        	}
        }
    }
</script>
