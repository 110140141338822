<template>
	<option v-if="defaultOption" value="" readonly>
		{{ defaultOption }}
	</option>
	<option v-for="choice in choices" :key="choice[0]" :value="choice[0]">
		{{ choice[1] }}
	</option>
</template>
<script>
export default {
	props: ["defaultOption", "choices"],
};
</script>
