<template>
	<div class="form-valide">
		<error-message type="alert" class="mb-5" :error-list="generalErrors" />
		<div class="row">
			<div class="col-xl-6">
				<input-group :required="true" :label="translations.source" :error-list="fieldErrors.source">
					<select
						class="form-control default-select"
						v-model="form.source"
						:disabled="disabledInputs?.source"
					>
						<option value="" readonly>
							{{ translations.please_select }}
						</option>
						<option v-for="source in marketingSources" :key="source.id" :value="source.id">
							{{ source.name }}
						</option>
					</select>
				</input-group>

				<input-group :required="true" :label="translations.brand_name" :error-list="fieldErrors.brand_name">
					<input
						type="text"
						class="form-control"
						v-model="form.brand_name"
						:disabled="disabledInputs?.brand_name"
					/>
				</input-group>

				<input-group :required="true" :label="translations.lead_class" :error-list="fieldErrors.lead_class">
					<select
						v-model="form.lead_class"
						:disabled="disabledInputs?.lead_class"
						class="form-control default-select"
					>
						<enum-choices :default-option="translations.please_select" :choices="leadClasses">
						</enum-choices>
					</select>
				</input-group>
			</div>
			<div class="col-xl-6">
				<input-group :required="true" :label="translations.campaign_name" :error-list="fieldErrors.campaign">
					<select
						class="form-control default-select"
						v-model="form.campaign"
						:disabled="disabledInputs?.campaign"
					>
						<option value="" readonly>
							{{ translations.please_select }}
						</option>
						<option v-for="campaign in campaigns" :key="campaign.id" :value="campaign.id">
							{{ campaign.name }}
						</option>
					</select>
				</input-group>

				<input-group :label="translations.notes" :error-list="fieldErrors.note">
					<input
						type="text"
						class="form-control"
						v-model="form.note"
						:placeholder="translations.notes_placeholder"
						:disabled="disabledInputs?.note"
					/>
				</input-group>

				<div class="form-group row">
					<div class="col-lg-8 ml-auto">
						<button @click="send" class="btn btn-primary" :class="{ 'disabled': formLoading }">
							{{ translations.save }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import FormMixin from "@mixins/Form";
export default {
	mixins: [FormMixin],
	props: ["campaigns", "leadClasses", "marketingSources"],
};
</script>