<template>
    <div class="form-valide">
        <div class="col-xl-6">
            <div class="input-group mb-3">
                <select class="form-control default-select" v-model="treatment" :disabled="disabledInputs?.treatment">
                    <option value="" readonly>
                        {{ translations.please_select }}
                    </option>
                    <option v-for="treatment in treatments" :key="treatment.id" :value="treatment">
                        {{ treatment.name }}
                    </option>
                </select>
                <div class="input-group-append">
                    <button @click="addNewSaleItems" :disabled="!treatment?.id" class="btn btn-primary" type="button">
                        {{ translations.add }}
                    </button>
                </div>
            </div>
        </div>

        <div v-if="form?.sale_items?.length" class="form-valide mb-5">
            <error-message type="alert" class="mb-5" :error-list="generalErrors" />
            <div class="card-body mt-5">
                <h3 class="h3 text-primary">{{ translations.new_sale }}</h3>
                <div class="table-responsive mt-3">
                    <table class="table table-responsive-md">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>
                                    <strong>{{ translations.product_name }}</strong>
                                </th>
                                <th>
                                    <strong>{{ translations.product_quantity }}</strong>
                                </th>
                                <th>
                                    <strong>{{ translations.unit_price }}</strong>
                                </th>
                                <th>
                                    <strong>{{ translations.total_price }}</strong>
                                </th>
                                <th>
                                    <strong>{{ translations.discount }}</strong>
                                </th>
                                <th>
                                    <strong>{{ translations.discounted_amount }}</strong>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(item, i) in form.sale_items" :key="i">
                                <tr>
                                    <td class="text-center">{{ i + 1 }}</td>
                                    <td>{{ item.treatment_display }}</td>
                                    <td>
                                        <input type="number" class="form-control" v-model="item.quantity" />
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" v-model="item.unit_price" />
                                    </td>
                                    <td class="text-center">{{ currencyUnit }} {{ (item.quantity * item.unit_price)?.toFixed(2) }}</td>
                                    <td>
                                        <div class="input-group">
                                            <input type="number" class="form-control" v-model="item.discount_value" />
                                            <error-message v-if="false" :error-list="errorList" />
                                            <div class="input-group-append">
                                                <select class="form-control" v-model="item.discount_type" required>
                                                    <option
                                                        v-for="dt in discountTypes"
                                                        :key="dt.value"
                                                        :value="dt.value"
                                                    >
                                                        {{ dt.display }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span v-text="item.discount_type == 'amount' ? currencyUnit : '%'"></span>
                                        {{ calculateItemPrice(item)?.toFixed(2) }}
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <span
                                                @click="removePendingSaleItem(item, i)"
                                                role="button"
                                                class="btn btn-danger shadow btn-xs sharp"
                                            >
                                                <i class="fa fa-remove"></i>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="item.errBag">
                                    <td colspan="8">
                                        <div class="alert alert-danger">
                                            <error-message :error-list="item.errBag" />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <hr />
                    <div class="text-right col-12">
                        <h4 class="h4 text-primary">{{ translations.sale_total_price }}: {{ totalFormPrice?.toFixed(2) }}</h4>
                    </div>
                    <hr />

                    <div class="form-group row mt-3">
                        <div class="col-lg-12 text-right">
                            <button @click="validateAndSend" class="btn btn-primary" :class="{ disabled: formLoading }">
                                {{ translations.save }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-responsive-md">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>
                                <strong>{{ translations.product_name }}</strong>
                            </th>
                            <th>
                                <strong>{{ translations.product_quantity }}</strong>
                            </th>
                            <th>
                                <strong>{{ translations.unit_price }}</strong>
                            </th>
                            <th>
                                <strong>{{ translations.total_price }}</strong>
                            </th>
                            <th>
                                <strong>{{ translations.discount }}</strong>
                            </th>
                            <th>
                                <strong>{{ translations.discounted_amount }}</strong>
                            </th>
                            <th>
                                <strong>{{ translations.sale_no }}</strong>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(sale, index) in sales" :key="sale.id">
                            <tr>
                                <td>{{ sale.id }}</td>
                                <td>{{ sale.treatment_name }}</td>
                                <template v-if="!sale.editMode">
                                    <td>{{ sale.quantity }}</td>
                                    <td>{{ currencyUnit }} {{ sale.unit_price }}</td>
                                    <td>{{ currencyUnit }} {{ sale.quantity * sale.unit_price }}</td>
                                    <td>{{ sale.discount_type == "amount" ? currencyUnit : "%" }} {{ sale.discount_value }}</td>
                                    <td>{{ currencyUnit }} {{ sale.total_price }}</td>
                                </template>
                                <template v-else>
                                    <td>
                                        <input type="number" class="form-control" v-model="sale.form.quantity" />
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" v-model="sale.form.unit_price" />
                                    </td>
                                    <td>{{ sale.form.quantity * sale.form.unit_price }} $</td>
                                    <td>
                                        <div class="input-group">
                                            <input
                                                type="number"
                                                class="form-control"
                                                v-model="sale.form.discount_value"
                                            />
                                            <div class="input-group-append">
                                                <select class="form-control" v-model="sale.form.discount_type" required>
                                                    <option
                                                        v-for="dt in discountTypes"
                                                        :key="dt.value"
                                                        :value="dt.value"
                                                    >
                                                        {{ dt.display }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span v-text="sale.form.discount_type == 'amount' ? currencyUnit : '%'"></span>
                                        {{ calculateItemPrice(sale.form)?.toFixed(2) }}
                                    </td>
                                </template>
                                <td>#{{ sale.sale }}</td>
                                <td>
                                    <div class="d-flex">
                                        <template v-if="!sale.editMode">
                                            <span
                                                @click="switchEditMode(sale)"
                                                role="button"
                                                class="btn btn-primary shadow btn-xs sharp mr-1"
                                            >
                                                <i class="fa fa-pencil"></i>
                                            </span>
                                            <span
                                                @click="deleteSaleItem(sale, index)"
                                                role="button"
                                                class="btn btn-danger shadow btn-xs sharp"
                                            >
                                                <i class="fa fa-trash"></i>
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span
                                                @click="updateSaleItem(sale, index)"
                                                role="button"
                                                class="btn btn-primary shadow btn-xs sharp mr-1"
                                            >
                                                <i class="fa fa-save"></i>
                                            </span>
                                            <span
                                                @click="sale.editMode = false"
                                                role="button"
                                                class="btn btn-danger shadow btn-xs sharp"
                                            >
                                                <i class="fa fa-remove"></i>
                                            </span>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="sale.editMode && sale.form?.errBag?.length">
                                <td colspan="9">
                                    <div class="alert alert-danger">
                                        <error-message :error-list="sale.form.errBag" />
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <tr>
                            <td colspan="5"></td>
                            <td>{{ translations.total_form_price }}</td>
                            <td>{{ currencyUnit }} {{ totalSalesPrice?.toFixed(2) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import FormMixin from "@mixins/Form";
export default {
    mixins: [FormMixin],
    props: ["treatments", "salesRecords", "leadId", "urlSchemes", "currencyUnit"],
    data() {
        return {
            treatment: null,
            sales: [],
            discountTypes: [
                { value: "amount", display: this.currencyUnit },
                { value: "percentage", display: "%" },
            ],
            listLoading: false,
            newSaleItem: {
                quantity: 1,
                unit_price: 0,
                discount_value: 0,
                discount_type: "amount",
                total_price: 0,
            },
        };
    },
    computed: {
        totalFormPrice() {
            const items = this.form.sale_items;
            if (!items?.length) {
                return;
            }

            let totalPrice = 0;
            for (var i = 0; i < items.length; i++) {
                const item = items[i];
                totalPrice += this.calculateItemPrice(item);
            }

            return totalPrice;
        },
        totalSalesPrice(){
            const items = this.sales;
            let totalPrice = 0;
            for(let item of items){
                totalPrice += Number(item.total_price);
            }
            return totalPrice;
        }
    },
    methods: {
        validate(item) {
            const price = this.calculateItemPrice(item);
            const errBag = [];

            item.errBag = false;
            if (item.quantity < 1) {
                errBag.push(this.translations.missing_quantity);
            }
            if (item.unit_price < 0) {
                errBag.push(this.translations.unit_price_cannot_be_negative);
            }
            if (item.discount_value < 0) {
                errBag.push(this.translations.discount_cannot_be_negative);
            }
            if (price < 0) {
                errBag.push(this.translations.price_cannot_be_negative);
            }

            if (errBag.length) {
                item.errBag = errBag;
                return false;
            }
            return true;
        },
        validateAndSend() {
            const items = this.form.sale_items;
            let validationFailed = false;

            for (var i = 0; i < items.length; i++) {
                if (!this.validate(items[i])) {
                    validationFailed = true;
                }
            }

            if (validationFailed) {
                return false;
            }
            return this.send();
        },
        calculateItemPrice(item) {
            let itemPrice = 0;
            if (item.discount_type == "amount") {
                itemPrice = item.quantity * item.unit_price - item.discount_value;
            } else if (item.discount_type == "percentage") {
                itemPrice = item.quantity * item.unit_price * ((100 - item.discount_value) / 100);
            }
            return itemPrice;
        },
        addNewSaleItems() {
            if (!this.treatment?.id) {
                return false;
            }
            if (!this.form?.sale_items?.length) {
                this.form = {
                    sale_items: [],
                    lead: this.leadId,
                };
            }
            let saleItem = { ...this.newSaleItem };
            saleItem.treatment = this.treatment.id;
            saleItem.treatment_display = this.treatment.name;
            this.form.sale_items.push(saleItem);
        },
        removePendingSaleItem(item, index) {
            if (this.form.sale_items[index] !== item) {
                index = this.form.sale_items.indexOf(item);
            }
            this.form.sale_items.splice(index, 1);
        },
        switchEditMode(item) {
            item.editMode = true;
            if (!item.form) {
                item.form = { ...item };
            }
        },
        updateSaleItem(item, index) {
            const form = item.form;
            // validate
            if (!this.validate(form)) {
                return;
            }
            // update remotely
            const url = this.urlSchemes.update_item.replace("0000", item.id);
            axios({ url: url, data: form, method: "PATCH" })
                .then(({ data }) => {
                    // update client
                    this.sales[index] = data;
                    this.toast("success", this.translations.success_message || "Success");
                })
                .catch(({ response }) => {
                    this.toast("warning", this.translations.error_message || "Error");
                })
                .finally(() => {
                    this.formLoading = false;
                });
        },
        deleteSaleItem(item, index) {
            if (this.formLoading) {
                return;
            }
            // delete remotely
            this.window
                .swal({
                    title: this.translations.confirm_sale_item_message,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: this.translations.yes,
                    cancelButtonText: this.translations.cancel,
                })
                .then((confirmed) => {
                    if (!confirmed.value) {
                        return;
                    }
                    this.formLoading = true;

                    const url = this.urlSchemes.delete_item.replace("0000", item.id);
                    axios({ url: url, method: "DELETE" })
                        .then(({ data }) => {
                            // remove from display
                            if (this.sales[index] !== item) {
                                index = this.sales.indexOf(item);
                            }
                            this.sales.splice(index, 1);
                        })
                        .catch(({ response }) => {
                            this.toast("warning", this.translations.error_message || "Error");
                        })
                        .finally(() => {
                            this.formLoading = false;
                        });
                });
        },
        clearSale() {
            this.form = {};
        },
        onFormCreated(response) {
            this.sales = response.data.sale_items;
        },
        onFormAfterSuccess(response) {
            this.clearSale();
        },
        convertSaleRecords(salesRecords) {
            this.sales = [];
            for (var i = 0; i < salesRecords.length; i++) {
                if (!salesRecords[i]) continue;
                for (var z = 0; z < salesRecords[i].sale_items.length; z++) {
                    if (salesRecords[i].sale_items[z]) {
                        this.sales.push(salesRecords[i].sale_items[z]);
                    }
                }
            }
        },
    },
    created() {
        if (this.salesRecords) {
            this.convertSaleRecords(this.salesRecords);
        }
    },
};
</script>